// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]4.4.0-8726(bf8a6a92f1)-C07/07/2022-14:23:28-B07/07/2022-14:24:27' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]4.4.0-8726(bf8a6a92f1)-C07/07/2022-14:23:28-B07/07/2022-14:24:27",
  branch: "master",
  latestTag: "4.4.0",
  revCount: "8726",
  shortHash: "bf8a6a92f1",
  longHash: "bf8a6a92f18ba89dbed491805b5c743c05fd35f9",
  dateCommit: "07/07/2022-14:23:28",
  dateBuild: "07/07/2022-14:24:27",
  buildType: "Ansible",
  } ;
