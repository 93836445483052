<p-toolbar styleClass="p-mb-4">
  <ng-template pTemplate="left" *ngIf="canEdit()">
    <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    <button pButton
            class="mg-r-15"
            label="AJOUTER"
            pTooltip="Ajouter une denrée sans approvisionnement"
            icon="fa fa-plus"
            (click)="openDialogAjoutProduitDeclinaison()">
    </button>
  </ng-template>
  <ng-template pTemplate="right">
    <button
      pButton
      type="button"
      icon="fas fa-trash"
      (click)="deleteValues()"
      [disabled]="!canEdit() || !selectedRows.length"
      pTooltip="Supprimer les éléments sélectionnés">
    </button>
  </ng-template>
</p-toolbar>
<dx-data-grid
  [dataSource]="udpProduitsDeclinaisonsNonApprovisionnesList"
  keyExpr="id"
  [height]="utils.getWindowAvailableHeight(250)"
  width="100%"
  [allowColumnResizing]="true"
  [hoverStateEnabled]="true"
  [rowAlternationEnabled]="true"
  [showRowLines]="true"
  [showBorders]="true"
  [(selectedRowKeys)]="selectedRows"
  #grid>
  <dxo-selection
    [selectAllMode]="allMode"
    [showCheckBoxesMode]="checkBoxesMode"
    mode="multiple"
  ></dxo-selection>
  <dxi-column dataField="produitDeclinaisonLibelle" caption="Déclinaison" [width]="100"
              [filterOperations]="['contains']"
              [allowFiltering]="true">
  </dxi-column>

  <dxo-filter-row [visible]="true"></dxo-filter-row>

</dx-data-grid>

<yo-dialog-ajout-produits-declinaisons-non-approvisionnes></yo-dialog-ajout-produits-declinaisons-non-approvisionnes>
