<div class="d-flex flex-grow-1" [style.height]="'calc(72vh)'">
  <div class="d-flex flex-column">

    <div>
      <p-tabMenu [model]="tabMenuContext" [activeItem]="activeItem"></p-tabMenu>

      <!--  PANEL DENREE-->
      <ng-container *ngIf="activeItem.id === TabMenuContext.denree">
        <span class="mg-l-5">
          <button
            pButton label="Ajouter"
            pTooltip="Ajouter des denrées au contexte"
            [disabled]="isDisabled()"
            icon="fa fa-plus"
            type="button"
            [style]="{'margin-bottom':'10px','margin-top':'10px'}"
            (click)="addDenree()">
          </button>
        </span>

        <dx-data-grid
          [dataSource]="selectedDenreeList"
          keyExpr="id"
          [height]="utils.getWindowAvailableHeight(400)"
          [width]="utils.getWindowAvailableWidth(300)"
          (onSelectionChanged)="onSelectionChangedUdp($event)"
          [hoverStateEnabled]="true"
          [allowColumnResizing]="true"
          [rowAlternationEnabled]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #gridDenree>
          <dxi-column dataField="id" alignment="center" caption="Actions" [width]="80"
                      [allowFiltering]="false"
                      [allowGrouping]="false"
                      cellTemplate="actionsCellTemplate">
          </dxi-column>
          <dxi-column dataField="libelle" alignment="left" caption="Déclinaison" [width]="300"
                      [allowFiltering]="true">
          </dxi-column>
          <dxi-column dataField="code" alignment="left" caption="Code" [width]="200"
                      [allowFiltering]="true">
          </dxi-column>
          <dxi-column dataField="site" alignment="left" caption="Site" [width]="200"
                      [allowFiltering]="true"
                      cellTemplate="siteCellTemplate">
          </dxi-column>

          <div *dxTemplate="let cell of 'actionsCellTemplate'">
            <yo-cell-button (yoNavigation)="removeDenree(cell.row.data)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="30"
                            [yoDisabled]="isDisabled()"
                            [yoTextAlign]="'center'"
                            [yoIconClass]="'fa fa-trash'"
                            pTooltip="Supprimer la denrée"
                            tooltipPosition="right"
                            showDelay="500">
            </yo-cell-button>
          </div>
          <div *dxTemplate="let cell of 'siteCellTemplate'">
            {{cell.row.data.site.libelle}}
          </div>
          <dxo-pager
            [showPageSizeSelector]="false"
            [showNavigationButtons]="true"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} Déclinaison(s)">
          </dxo-pager>
        </dx-data-grid>

      </ng-container>

      <!--  PANEL FOURNISSSEUR-->
      <ng-container *ngIf="activeItem.id === TabMenuContext.fournisseurs">
        <div *ngIf="selectedFournisseurList.length > 0"
             class="search-element d-flex flex-row align-items-start mg-l-5 "
             [style]="{'margin-bottom':'10px','margin-top':'10px'}">
          <div class="d-flex flex-column mg-r-5">
            <div class="d-flex flex-row align-items-center">
              <i class="fas fa-truck mg-r-5"></i> <strong class="mg-r-5 inline">selection :</strong>
            </div>
            <div class="mg-t-5">
              <button pButton icon="fas fa-sort-numeric-up" pTooltip="Gérer l'ordre de priorité des fournisseurs"
                      tooltipPosition="bottom" (click)="openDialogPrisization()"
                      label="Prioriser"
                      class="p-button-secondary">
              </button>
            </div>
          </div>
          <div [style.width.px]="utils.getWindowAvailableWidth(415)">
          <p-chips #basketFournisseur
                   (keydown)="utils.onChangeDisabledInputChips($event)"
                   [(ngModel)]="selectedFournisseurList"
                   [disabled]="isDisabled()"
                   (onRemove)="onRemoveChipFournisseur($event)">
            <ng-template let-item pTemplate="item">
              <i style="margin-right:2em; padding-bottom: 2px;vertical-align: middle">({{item.ordre}}
                ) {{item.libelle}}</i>
            </ng-template>
          </p-chips>
          </div>
        </div>

        <dx-data-grid
          [ngClass]="selectedFournisseurList.length === 0 ? 'mg-t-10' : ''"
          [dataSource]="fournisseurList"
          keyExpr="id"
          [(selectedRowKeys)]="selectedRowKeysFournisseur"
          [height]="utils.getWindowAvailableHeight(450)"
          [width]="utils.getWindowAvailableWidth(300)"
          (onSelectionChanged)="onSelectionChangedFournisseur($event)"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #gridFournisseur>

          <!-- COLONNES-->
          <dxi-column dataField="libelle" alignment="left" caption="Fournisseur" [width]="300"
                      [allowFiltering]="true" cellTemplate="fournisseurCellTemplate">
          </dxi-column>
          <dxi-column dataField="site" alignment="left" caption="Site" [width]="200"
                      [allowFiltering]="true"
                      cellTemplate="siteCellTemplate">
          </dxi-column>
          <dxi-column dataField="code" alignment="left" caption="Code" [width]="50"
                      [allowFiltering]="true">
          </dxi-column>

          <!-- TEMPLATES-->
          <div *dxTemplate="let cell of 'siteCellTemplate'">
            {{cell.row.data.site.libelle}}
          </div>
          <div class="d-flex justify-content-between" *dxTemplate="let cell of 'fournisseurCellTemplate'">
            <div>
              {{cell.row.data.libelle}}
            </div>
            <div *ngIf="!utils.isNullOrEmpty(cell.row.data.parent)"
                 class="size-icon"
                 tooltipPosition="top"
                 [pTooltip]="'Filiale de  : ' +cell.row.data.parent.libelle+ '  (code : '+cell.row.data.parent.code+')'">
              <span class="fa-stack fa-2x">
                <i class="fas fa-circle fa-stack-2x"></i>
                <strong class="fa-stack-1x custom-letter">F</strong>
              </span>
            </div>
          </div>

          <dxo-search-panel
            [visible]="true">
          </dxo-search-panel>

          <!-- OPTIONS-->
          <dxo-selection [mode]="modeSelectionDxDatagrid"></dxo-selection>
          <dxo-pager
            [showPageSizeSelector]="false"
            [showNavigationButtons]="true"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} Fournisseur(s)">
          </dxo-pager>
        </dx-data-grid>
      </ng-container>


      <!--  PANEL UNITE DE PRODUCTION-->
      <ng-container *ngIf="activeItem.id === TabMenuContext.uniteDeProduction">

        <div *ngIf="selectedUniteDeProductionList.length>0"
             class="search-element  d-flex flex-row align-items-center mg-l-5" [style]="{'margin-bottom':'10px','margin-top':'10px'}">
          <i class="fas fa-industry mg-r-5"></i> <strong class="mg-r-5 inline">selection : </strong>
          <div [style.width.px]="utils.getWindowAvailableWidth(415)">
          <p-chips #basketUniteDeProduction
                   (keydown)="utils.onChangeDisabledInputChips($event)"
                   [(ngModel)]="selectedUniteDeProductionList"
                   [disabled]="isDisabled()"
                   (onRemove)="onRemoveChipUniteDeProduction($event)">
            <ng-template let-item pTemplate="item">
              <i style="margin-right:2em; padding-bottom: 2px;vertical-align: middle"> {{item.libelle}}</i>
            </ng-template>
          </p-chips>
          </div>
        </div>

        <dx-data-grid
          [ngClass]="selectedUniteDeProductionList.length === 0 ? 'mg-t-10' : ''"
          [dataSource]="uniteDeProductionList"
          keyExpr="id"
          [(selectedRowKeys)]="selectedRowKeysUniteDeProduction"
          [height]="utils.getWindowAvailableHeight(450)"
          [width]="utils.getWindowAvailableWidth(300)"
          (onSelectionChanged)="onSelectionChangedUdp($event)"
          [hoverStateEnabled]="true"
          [rowAlternationEnabled]="true"
          [allowColumnResizing]="true"
          [showRowLines]="true"
          [showBorders]="true"
          #gridUdp>
          <dxi-column dataField="libelle" alignment="left" caption="Unité de production" [width]="300"
                      [allowFiltering]="true">
          </dxi-column>
          <dxi-column dataField="site" alignment="left" caption="Site" [width]="200"
                      [allowFiltering]="true"
                      cellTemplate="siteCellTemplate">
          </dxi-column>
          <div *dxTemplate="let cell of 'siteCellTemplate'">
            {{cell.row.data.site.libelle}}
          </div>
          <dxo-search-panel [visible]="true"></dxo-search-panel>
          <dxo-selection [mode]="modeSelectionDxDatagrid"></dxo-selection>
          <dxo-pager
            [showPageSizeSelector]="false"
            [showNavigationButtons]="true"
            [visible]="true"
            [showInfo]="true"
            infoText="{2} Unité(s) de production">
          </dxo-pager>
        </dx-data-grid>
      </ng-container>
    </div>
  </div>
</div>


<hr/>
<div class="d-flex justify-content-between">
  <div class="d-flex flex-column">
    <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>
    <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Completez les 3 onglets pour avoir
      accés à l'onglet suivant "Articles" </label></div>
  </div>
  <div>
      <span class="mg-r-5">
        <button pButton icon="fas fa-save" (click)="saveContext()"
                [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </span>
    <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
  </div>
</div>


<yo-search-denree></yo-search-denree>
<yo-dialog-priorisation-fournisseur></yo-dialog-priorisation-fournisseur>


