<p-card [style]="{'width': '300px',
'min-height':'calc(100vh - 180px)',
'max-height':'calc(100vh - 180px)',
'overflow-y': 'auto',
'overflow-x':'hidden',
'margin-right': '15px'}">

  <ng-template pTemplate="header">
    <i class="fas fa-search mg-t-10 mg-r-5 mg-l-15"></i> RECHERCHER
  </ng-template>

  <form [formGroup]="formSearch">

    <h3 class="first">Nom Déclinaison</h3>
    <input type="text"
           formControlName="denree"
           class="width-250">

    <h3 class="first">Code Déclinaison</h3>
    <input type="text"
           formControlName="codeDenree"
           class="width-250">

    <h3 class="first">Dénomination article</h3>
    <input type="text"
           pInputText
           formControlName="denominationArticle"
           class="width-250">

    <h3 class="first">Réf interne article </h3>
    <input type="text"
           formControlName="referenceInterneArticle"
           class="width-250">

    <h3 class="first">Lieux de stockage</h3>
    <p-tree [value]="tree"
            [filter]="true" filterMode="strict"
            styleClass="no-overflow"
            (selectionChange)="onChangeLieuxDeStockage($event)"
            selectionMode="checkbox" [(selection)]="selectedNodes">
    </p-tree>

    <h3 class="first">Seuils</h3>
    <div class="p-g" style="width:250px;margin-bottom:10px">
      <dx-radio-group formControlName="thresholdStocks" valueExpr="value" displayExpr="label" [items]="seuils" [value]="seuils[0]"></dx-radio-group>
    </div>

    <h3 class="first">Entrée à partir du</h3>
    <p-calendar
      firstDayOfWeek="1"
      appendTo="body"
      dateFormat="DD d MM yy"
      tooltipPosition="bottom"
      showDelay="500"
      [readonlyInput]="true"
      formControlName="dateEntree"
      [locale]="localeFr"
      [showIcon]="true"
      [showWeek]="true"
      [showButtonBar]="true">
    </p-calendar>

    <h3 class="first">Périmé avant le</h3>
    <p-calendar
      firstDayOfWeek="1"
      appendTo="body"
      dateFormat="DD d MM yy"
      tooltipPosition="bottom"
      showDelay="500"
      [readonlyInput]="true"
      formControlName="datePerime"
      [locale]="localeFr"
      [showIcon]="true"
      [showWeek]="true"
      [showButtonBar]="true">
    </p-calendar>

  </form>

</p-card>
