<dx-popup
  [style]="{'width':'calc(90vw)','height':'calc(80vh)'}"
  [showTitle]="true"
  title="CRÉER UNE NOUVELLE PROPOSITION DE COMMANDE"
  [dragEnabled]="true"
  [closeOnOutsideClick]="true"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="displayDialog"
  [fullScreen]="fullScreen"
>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    [options]="{
            icon: 'fullscreen',
            onClick: toggleFullScreen
        }">
  </dxi-toolbar-item>

  <div class="d-flex flex-row align-content-center mg-b-10">
    <div class="mg-r-10 align-self-center">
      <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
    </div>
    <div class="mg-r-10 align-self-center">
      <button pButton
              class="mg-r-15 mg-t-10"
              label="AJOUTER"
              icon="fa fa-plus"
              [pTooltip]="'Ajouter des déclinaisons'"
              (click)="openAjoutDenree()"></button>
    </div>
    <div class="mg-r-10 align-self-center">
      <label class="mg-r-5">Unité de production : </label>
      <p-dropdown [options]="uniteDeProductionList"
                  [(ngModel)]="uniteDeProductionSelected"
                  (onChange)="onChangeUniteDeProduction($event)"
                  optionLabel="libelle"
                  [filter]="true"
                  filterBy="libelle"
                  [showClear]="true"
                  placeholder="Selectionner une unité de production">
      </p-dropdown>
    </div>
    <div class="mg-r-10 align-self-center">
      <label class="mg-r-5">Date de livraison au plus tard : </label>
    </div>
    <div class="mg-r-10 align-self-center">
      <dx-date-box #datebox id="dateLiv" [min]="minDate" [value]="dateSelected" type="date" (onValueChanged)="changeDateLivraisonMax($event)"> </dx-date-box>
    </div>
    <div class="mg-r-30 d-flex align-items-center">
      <label [pTooltip]="'Scénario de pré-sélection des articles'" class="mg-r-5">Scénario : </label>
      <p-dropdown [options]="optionScenarioArticle"
                  [(ngModel)]="scenarioArticleSelected"
                  optionLabel="key"
                  (onChange)="onChangeScenarioArticle($event)"
                  [filter]="true"
                  [showClear]="true">
      </p-dropdown>
    </div>
  </div>

  <dx-data-grid
    [dataSource]="commandeDenreeList"
    keyExpr="idProduitDeclinaison"
    height="80%"
    [hoverStateEnabled]="true"
    [rowAlternationEnabled]="true"
    [allowColumnResizing]="true"
    [showRowLines]="true"
    [showBorders]="true"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditingStart)="onEditingStart($event)"
    #gridDenreeSelected>
    <dxi-column alignment="center" caption="Action(s)" cellTemplate="actionsCellTemplate"></dxi-column>
    <dxi-column dataField="libelleProduitDeclinaison" alignment="left" caption="Déclinaison"
                [allowEditing]="false"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="codeProduitDeclinaison" alignment="left" caption="Code"
                [allowEditing]="false"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="" alignment="left" caption="Fournisseur" cellTemplate="providerCellTemplate"
                [allowEditing]="false"
                [allowFiltering]="true">
    </dxi-column>
    <dxi-column dataField="libelleArticleSelected" caption="Article"
                [allowEditing]="true"
                [allowFiltering]="false"
                cellTemplate="articleCellTemplate"
                editCellTemplate="editArticleCellTemplate">
    </dxi-column>
    <dxi-column dataField="quantiteUT" alignment="left" caption="Quantité UT"
                cellTemplate="quantiteUTCellTemplate"
                [allowFiltering]="false"
                [allowEditing]="true">
    </dxi-column>
    <dxi-column dataField="quantiteUT" alignment="left" caption="Quantité UF"
                cellTemplate="quantiteUFCellTemplate"
                [allowFiltering]="false"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="quantiteUC" alignment="left" caption="Quantité UC"
                cellTemplate="quantiteUCCellTemplate"
                [allowFiltering]="false"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="quantiteUT" alignment="left" caption="Quantité arrondie en UT"
                cellTemplate="quantiteUCArrondieCellTemplate"
                [allowFiltering]="false"
                [allowEditing]="false">
    </dxi-column>
    <dxi-column dataField="dateLivraisonPossible" alignment="left" dataType="date" caption="Date de livraison"
                [allowEditing]="false"
                [allowFiltering]="true">
    </dxi-column>

    <!--    TEMPLATE-->
    <div *dxTemplate="let cell of 'actionsCellTemplate'">
      <yo-cell-button (yoNavigation)="removeFeed(cell.row.data)"
                      [yoTooltipShowDelay]="1500"
                      [yoWidthPercent]="30"
                      [yoTextAlign]="'center'"
                      [yoIconClass]="'fa fa-trash'"
                      pTooltip="Supprimer cette denrée de la sélection"
                      tooltipPosition="right"
                      showDelay="500"
      ></yo-cell-button>
    </div>

    <div *dxTemplate="let cell of 'providerCellTemplate'">
      {{ getProviderLabel(cell.row.data) }}
    </div>

    <div *dxTemplate="let cell of 'editArticleCellTemplate'">
      <dx-drop-down-box
        [dropDownOptions]="dropDownOptions"
        [dataSource]="cell.data.catalogueAchatList"
        [(value)]="cell.value"
        displayExpr="produitArticle.libelle"
        valueExpr="id"
        contentTemplate="contentTemplate">

        <div *dxTemplate="let e of 'contentTemplate'">
          <yo-grille-remplacer-article
            [udpSfPrefereList]="udpSfPrefereList"
            [articlesDisponibles]="cell.data.catalogueAchatList"
            [unicityIdForGroupeName]=" cell.data.idProduitDeclinaison"
            [selectedGacaViewModel]="cell.data.gacaSelected"
            (updatePrixUnitaireUF)="updatePrixUnitaireCommande(e.component, $event, cell.data)">
          </yo-grille-remplacer-article>
        </div>
      </dx-drop-down-box>
    </div>


    <div *dxTemplate="let cell of 'articleCellTemplate'">
      <span [ngClass]="utils.isNullOrEmpty(cell.row.data.libelleArticleSelected) ? 'font-warning' :''">
        {{utils.isNullOrEmpty(cell.row.data.libelleArticleSelected) ? 'Pas d\'article pour cette denrée' : cell.row.data.libelleArticleSelected}}
      </span>
      <br/>
      <yo-info-lotmarche-article
        *ngIf="!utils.isNullOrEmpty(cell.row.data.libelleArticleSelected)"
        [uniteDeProductionId]="uniteDeProductionSelected.id"
        [lotMarchePdLumpCa]="getLmPdUpCa(cell.row.data)">
      </yo-info-lotmarche-article>
    </div>

    <div *dxTemplate="let cell of 'quantiteUTCellTemplate'">
      <div *ngIf="!utils.isNullOrEmpty(cell.row.data.libelleArticleSelected)">
        <span [ngClass]="cell.row.data?.quantiteUT === 0 ? 'font-warning': ''">{{cell.row.data?.quantiteUT}}</span>
        <span [pTooltip]="getUniteDeMesureLibelle(cell.row.data, TYPE_UNITE_DE_MESURE.uniteTechnique)"
              [ngClass]="cell.row.data?.quantiteUT === 0 ? 'font-warning': ''">
          {{getUniteDeMesureAbrevation(cell.row.data, TYPE_UNITE_DE_MESURE.uniteTechnique)}}
        </span>
      </div>
    </div>

    <div *dxTemplate="let cell of 'quantiteUFCellTemplate'">
      <div *ngIf="!utils.isNullOrEmpty(cell.row.data.libelleArticleSelected)">
        <span>{{getQuantiteUFFromUT(cell.row.data)}}</span>
        <span [pTooltip]="getUniteDeMesureLibelle(cell.row.data, TYPE_UNITE_DE_MESURE.uniteDeFacturation)">
          {{getUniteDeMesureAbrevation(cell.row.data, TYPE_UNITE_DE_MESURE.uniteDeFacturation)}}
        </span>
      </div>
    </div>

    <div *dxTemplate="let cell of 'quantiteUCCellTemplate'">
      <div *ngIf="!utils.isNullOrEmpty(cell.row.data.libelleArticleSelected)"
           [pTooltip]="getQuantiteUCTooltip(cell.row.data)">
        <span>{{getQuantiteUCFromUT(cell.row.data)}} </span>
        <span [pTooltip]="getUniteDeMesureLibelle(cell.row.data, TYPE_UNITE_DE_MESURE.uniteDeCommande)">
          {{getUniteDeMesureAbrevation(cell.row.data, TYPE_UNITE_DE_MESURE.uniteDeCommande)}}
        </span>
      </div>
    </div>

    <div *dxTemplate="let cell of 'quantiteUCArrondieCellTemplate'">
      <div *ngIf="!utils.isNullOrEmpty(cell.row.data.libelleArticleSelected)">
        <span>{{getQuantiteUTFromUC(cell.row.data)}}</span>
        <span [pTooltip]="getUniteDeMesureLibelle(cell.row.data, TYPE_UNITE_DE_MESURE.uniteTechnique)">
          {{getUniteDeMesureAbrevation(cell.row.data, TYPE_UNITE_DE_MESURE.uniteTechnique)}}
        </span>
      </div>
    </div>

    <!--    OPTION-->
    <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false">
    </dxo-editing>

    <dxo-pager
      [showPageSizeSelector]="false"
      [showNavigationButtons]="true"
      [visible]="true"
      [showInfo]="true"
      infoText="{2} Déclinaison(s)">
    </dxo-pager>
  </dx-data-grid>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Ajoutez des denrées à votre panier
        puis sélectionnez l'unité de production qui va en bénéficier ainsi que la date de livraison souhaitée. Vérifiez
        ensuite que les articles
        préselectionnés correspondent à vos besoins</label></div>
    </div>
    <div class="d-flex">
      <span class="mg-r-5">
        <button pButton icon="fas fa-save" (click)="checkBeforeSavePropositionList()"
                [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </span>
      <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
    </div>
  </div>
  <!-- </p-dialog> -->
</dx-popup>

<!--DIALOG AJOUT DENREE POUR LA PROPOSITION DE COMMANDE-->
<yo-ajout-denree-proposition-commande></yo-ajout-denree-proposition-commande>
