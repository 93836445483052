<p-dialog
  [modal]="true"
  (onHide)="closeDialog()"
  [dismissableMask]="false"
  position="top"
  [contentStyle]="{'overflow':'visible'}"
  [style]="{'width':'80vw'}"
  [(visible)]="displayDialog">

  <ng-template pTemplate="header">
    <div class="p-dialog-title">
     Remplacer la déclinaison {{produitUtilise.libelle}}
    </div>
  </ng-template>

<!--  BODY-->
  <div class="mg-t-5" [style.max-height]="'calc(80vh)'" style=" overflow-y:auto;overflow-x: hidden">

    <dx-data-grid
      [dataSource]="customStore"
      keyExpr="id"
      [height]="utils.getWindowAvailableHeight(470)"
      [width]="'calc(75vw)'"
      columnResizingMode="widget"
      [cacheEnabled]="false"
      [remoteOperations]="true"
      [allowColumnReordering]="true"
      (onSelectionChanged)="onSelectionChanged($event)"
      [allowColumnResizing]="true"
      [hoverStateEnabled]="true"
      [rowAlternationEnabled]="true"
      [showRowLines]="true"
      [showBorders]="true"
      #grid>
      <dxi-column dataField="site.libelle" alignment="center" caption="Site" [width]="200"
                  [allowFiltering]="false"
                  cellTemplate="siteCellTemplate">
      </dxi-column>
      <dxi-column dataField="libelle" alignment="center" caption="Libelle" [width]="400"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="code" alignment="center" caption="Code" [width]="300"
                  [allowFiltering]="true">
      </dxi-column>
      <dxi-column dataField="reference" alignment="center" caption="Reference" [width]="300"
                  [allowFiltering]="true">
      </dxi-column>


      <div *dxTemplate="let cell of 'siteCellTemplate'">
        <yo-site [siteLibelle]="cell.data.site.libelle"
                 [siteId]="cell.data.site.id">
        </yo-site>
      </div>

      <dxo-selection
        [selectAllMode]="'allPages'"
        [showCheckBoxesMode]="'onClick'"
        mode="single">
      </dxo-selection>
      <dxo-paging  [pageSize]="50"></dxo-paging>
      <dxo-pager
        [showPageSizeSelector]="true"
        [allowedPageSizes]="[20, 50, 100]"
        [showNavigationButtons]="true"
        [visible]="true"
        [showInfo]="true"
        infoText="{2} Déclinaison(s)">
      </dxo-pager>

      <dxo-filter-row [visible]="true"></dxo-filter-row>

      <dxo-sorting mode="multiple"></dxo-sorting>

    </dx-data-grid>

    <div class="mg-t-10 d-flex justify-content-center">
      <p-card>
        <p-header class="d-flex justify-content-center">
          <strong class="mg-t-10">Déclinaison utilisée</strong>
        </p-header>
        <div class="card-body">{{produitUtilise?.libelle}}</div>
      </p-card>

      <i class="mg-l-30 mg-r-30 fa-3x fas fa-long-arrow-alt-right align-self-center"></i>

      <p-card>
        <p-header class="d-flex justify-content-center">
          <strong class="mg-t-10">Déclinaison de substitution</strong>
        </p-header>
        <div class="card-denree-body">{{denreeSubstitution?.libelle}}</div>
      </p-card>
    </div>

    <div *ngIf="!this.utils.isNullOrEmpty(denreeSubstitution)" class="d-flex flex-row mg-t-10">
      <div class="d-flex align-items-center">
        <label class="mg-r-5"><strong>Pourcentage de substitution</strong></label>
        <div class="p-inputgroup">
          <input pInputText
                 type="number"
                 [(ngModel)]="pourcentageSubstitution">
          <span class="p-inputgroup-addon">%</span>
        </div>
      </div>
      <div class="mg-l-30 d-flex flex-column" *ngIf="denreeSubstitution">
        <div class="d-flex flex-row">
          <strong>1 {{produitUtilise.uniteDeMesure.code}}</strong>
          <p class="mg-l-5">de la denrée utilisé donnera</p>
        </div>
        <div class="d-flex flex-row">
          <strong>{{getMesureDenreeSubstitution() | number : '1.0-4'}} {{denreeSubstitution.uniteDeMesure.code}}</strong>
          <p class="mg-l-5">de la denrée de substitution</p>
        </div>
      </div>
    </div>
  </div>

  <hr/>
  <div class="d-flex justify-content-between">
    <div class="d-flex flex-column">
      <!--      <div>🗣️<em> N'oubliez pas d'enregistrer votre travail.</em></div>-->
      <!--      <div><label class="font-12 genlabel"><i class="fas fa-info-circle mg-r-5"></i> Completez les 3 onglets pour avoir-->
      <!--        accés à l'onglet suivant "Articles" </label></div>-->
    </div>
    <div>
      <span class="mg-r-5">
        <button pButton icon="fas fa-save" (click)="saveReplaceDenree()"
                [disabled]="isDisabledSaveBtn()" pTooltip="Enregistrer" tooltipPosition="top"
                showDelay="500" class="p-button-success">
        </button>
      </span>
      <span class="mg-r-5">
        <button pButton icon="fas fa-times" pTooltip="Fermer" (click)="closeDialog()"
                class="p-button-secondary">
        </button>
      </span>
    </div>
  </div>
</p-dialog>
