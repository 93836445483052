<p-card *ngIf="!utils.isNullOrEmpty(sps)">

  <div class="d-flex flex-column">

    <div>
      SORTIES POUR <strong>{{sps.planProduction.libelle | uppercase}}</strong>
    </div>

    <div class="d-flex justify-content-between  mg-t-5">
      <div>
        <p-selectButton [options]="chooseView"
                        [(ngModel)]="selectedVue"
                        (onChange)="changeView($event)"
                        [disabled]="isValiderSortiesDisabled()">
        </p-selectButton>
      </div>
      <div>
        <p-menu #menuTypeSortiePourProd appendTo="body" [popup]="true" [model]="itemsReceptions"></p-menu>
        <button type="button"
                pButton
                icon="fas fa-print"
                [disabled]="isDisabledPrintBtn()"
                (click)="menuTypeSortiePourProd.toggle($event)">
        </button>
      </div>
    </div>

    <div>
      <ng-container [ngSwitch]="sps.workflowInstance.vueSortiesPourProduction">

        <ng-container *ngSwitchCase="VUE_SORTIES_POUR_PRODUCTION.DENREE">
          <ng-container *ngTemplateOutlet="tplVueDenree"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="VUE_SORTIES_POUR_PRODUCTION.STOCK">
          <ng-container *ngTemplateOutlet="tplVueStock"></ng-container>
        </ng-container>

      </ng-container>
    </div>

    <div class="d-flex mg-t-3">
      <div class="mg-r-20">
        <i class="fas fa-square col-editable-color mg-r-5 border"></i>La colonne est modifiable
      </div>
      <div class="mr-auto">
        <strong>{{utils.getTotalRecordsLabel('sortie', sps.sortiesPourProductionList.length)}}</strong>
      </div>
      <div>
        <ng-container
          *ngTemplateOutlet="tplValiderSorties;context:{disabled:isValiderSortiesDisabled()}"></ng-container>
      </div>
    </div>

  </div>

</p-card>

<!--TEMPLATE VUE DENREE-->
<ng-template #tplVueDenree>

  <dx-data-grid
    [dataSource]="sps?.sortiesPourProductionList"
    keyExpr="id"
    [height]="utils.getWindowAvailableHeight(150)"
    [width]="dxSvc.getWidth(1.2)"
    [hoverStateEnabled]="true"
    [showBorders]="true"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onRowUpdated)="onRowUpdated($event)"
    [allowColumnResizing]="true"
    #grid>

    <dxi-column dataField="statutSortiePourProductionLibelle" caption="Statut" alignment="center"
                cellTemplate="tplStatut"
                width="100" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="dateSortie" caption="Date de sortie" alignment="left" cellTemplate="tplDateSortie"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="udpLibelle" caption="Unité de production" alignment="left"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="udpZdpLibelle" caption="Atelier" alignment="left" cellTemplate="tplAtelier"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="udpZdsLibelle" caption="Zone de stockage" alignment="left"
                width="120"
                [allowEditing]="false"></dxi-column>

    <dxi-column dataField="produitDeclinaisonLibelle" caption="Déclinaison" alignment="left"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUt" caption="Qté Prévue UT" alignment="right"
                cellTemplate="tplQuantitePrevueUt"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUs" caption="Qté Prévue US" alignment="right"
                cellTemplate="tplQuantitePrevueUs"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantiteReelleUs" caption="Qté Réelle US" alignment="right"
                headerCellTemplate="updateHeaderCellTemplate"
                cellTemplate="tplQuantiteReelleUs"
                [allowEditing]="true">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" [min]="0"
                           message="La Qté Réelle US doit être supérieure ou égale à 0"></dxi-validation-rule>
    </dxi-column>

    <dxo-selection
      [selectAllMode]="true"
      [showCheckBoxesMode]="'always'"
      mode="multiple"
    ></dxo-selection>

    <dxo-editing
      mode="cell"
      [allowUpdating]="true">
    </dxo-editing>

    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Rechercher..."></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>

    <dxo-header-filter
      [visible]="true"></dxo-header-filter>

    <dxo-filter-builder>
    </dxo-filter-builder>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder-popup
      [position]="popupPosition">
    </dxo-filter-builder-popup>

    <!--    TEMPLATE DATE SORTIE -->
    <div *dxTemplate="let cell of 'tplDateSortie'">
      {{cell.row.data.dateSortie}}
    </div>

    <!--    TEMPLATE ATELIER-->
    <div *dxTemplate="let cell of 'tplAtelier'">
      {{cell.row.data.udpZdpLibelle}}
    </div>


    <!--    TEMPLATE QUANTITE PREVUE UT-->
    <div *dxTemplate="let cell of 'tplQuantitePrevueUt'">
      {{cell.row.data.quantitePrevueUt | number:'1.4-4'}} <span
      [pTooltip]="cell.row.data.udmUtLibelle">{{cell.row.data.udmUtAbreviation}}</span>
    </div>

    <!--    TEMPLATE QUANTITE PREVUE US-->
    <div *dxTemplate="let cell of 'tplQuantitePrevueUs'">
      {{cell.row.data.quantitePrevueUs | number:'1.4-4'}} <span
      [pTooltip]="cell.row.data.udmUsLibelle">{{cell.row.data.udmUsAbreviation}}</span>
    </div>

    <!--    TEMPLATE QUANTITE REELLE US-->
    <div *dxTemplate="let cell of 'tplQuantiteReelleUs'">
      {{cell.row.data.quantiteReelleUs | number:'1.4-4'}} <span
      [pTooltip]="cell.row.data.udmUsLibelle">{{cell.row.data.udmUsAbreviation}}</span>
    </div>

    <!--    TEMPLATE STATUT-->
    <div *dxTemplate="let cell of 'tplStatut'">
        <span class="fa-stack mg-r-10" [pTooltip]="cell.row.data.statutSortiePourProductionLibelle">
              <i class="far fa-square fa-stack-2x"
                 [class.color-proposition-sortie]="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE"
                 [class.color-sortie-validee]="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.SORTIE_VALIDEE"></i>
              <strong
                *ngIf="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE"
                class="fa-stack-1x color-proposition-sortie">PS</strong>
              <strong
                *ngIf="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.SORTIE_VALIDEE"
                class="fa-stack-1x color-sortie-validee">SV</strong>
        </span>
    </div>

    <!-- UPDATE HEADER CELL TEMPLATE-->
    <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
      <div tooltipPosition="top" pTooltip="colonne modifiable">
        {{cell.column.caption}}
      </div>
    </div>

  </dx-data-grid>

</ng-template>

<!--TEMPLATE VALIDER SORTIES-->
<ng-template #tplValiderSorties let-disabled="disabled">
  <ng-container *ngIf="!disabled">
    <div>
      <button pButton label="VALIDER LES SORTIES" icon="fas fa-check"
              (click)="validerSorties()"></button>
    </div>
  </ng-container>

  <ng-container *ngIf="disabled">
    <div>
      <i class="fas fa-check fa-2x  checked-color mg-r-5"></i> LES SORTIES DE
      <strong>{{sps.planProduction.libelle | uppercase}}</strong> SONT VALIDÉES.
    </div>
  </ng-container>
</ng-template>


<!--TEMPLATE VUE STOCK-->
<ng-template #tplVueStock>

  <dx-data-grid
    [dataSource]="sps?.sortiesPourProductionList"
    keyExpr="id"
    [allowColumnResizing]="true"
    columnResizingMode="widget"
    [width]="dxSvc.getWidth(1.15)"
    [height]="utils.getWindowAvailableHeight(150)"
    [hoverStateEnabled]="true"
    [showBorders]="true"
    (onCellPrepared)="onCellPrepared($event)"
    (onEditorPreparing)="onEditorPreparing($event)"
    (onRowUpdated)="onRowUpdated($event)"
    #grid>

    <dxi-column dataField="statutSortiePourProductionLibelle" caption="Statut" alignment="center"
                cellTemplate="tplStatut"
                width="40" [allowEditing]="false"></dxi-column>
    <dxi-column dataField="dateSortie" caption="Date de sortie" alignment="left" cellTemplate="tplDateSortie"
                [allowEditing]="false"
                width="100"
    ></dxi-column>
    <dxi-column dataField="udpLibelle" caption="Unité de production" alignment="left"
                width="120"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="udpZdpLibelle" caption="Atelier" alignment="left" cellTemplate="tplAtelier"
                width="120"
                [allowEditing]="false" width="180"></dxi-column>
    <dxi-column dataField="udpZdsLibelle" caption="Zone de stockage" alignment="left"
                width="120"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="produitDeclinaisonLibelle" caption="Déclinaison" alignment="left" cellTemplate="tplDenree"
                [allowEditing]="false" width="270"></dxi-column>
    <dxi-column dataField="stockDlc" caption="DLC" alignment="left" cellTemplate="tplDlc"
                width="120"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUt" caption="Qté Prévue UT" alignment="right"
                cellTemplate="tplQuantitePrevueUt"
                width="150"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantitePrevueUs" caption="Qté Prévue US" alignment="right"
                cellTemplate="tplQuantitePrevueUs"
                width="150"
                [allowEditing]="false"></dxi-column>
    <dxi-column dataField="quantiteReelleUs" caption="Qté Ajustée US" alignment="right"
                headerCellTemplate="updateHeaderCellTemplate"
                cellTemplate="tplQuantiteReelleUs"
                width="150"
                [allowEditing]="true">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="range" [min]="0"
                           message="La Qté Réelle US doit être supérieure ou égale à 0"></dxi-validation-rule>
      <!--      <dxi-validation-rule type="range" [max]="100"-->
      <!--                           message="La Qté Réelle US doit être inférieure ou égale à la quantité en stock"></dxi-validation-rule>-->
    </dxi-column>
    <dxi-column dataField="stockQuantite" caption="Qté en stock" alignment="right"
                cellTemplate="tplQuantiteEnStock"
                width="150"
                [allowEditing]="false"></dxi-column>
    <!--    <dxi-column dataField="id" caption="Reste à sortir" alignment="right"-->
    <!--                cellTemplate="tplResteASortir"-->
    <!--                width="150"-->
    <!--                [allowEditing]="false"></dxi-column>-->
    <dxi-column dataField="lotArticleLibelle" caption="Lot" alignment="left"
                width="120"
                [allowEditing]="false"></dxi-column>


    <dxo-selection
      [selectAllMode]="true"
      [showCheckBoxesMode]="'always'"
      mode="multiple"
    ></dxo-selection>

    <dxo-editing
      mode="cell"
      [confirmDelete]="false"
      [allowUpdating]="true">
    </dxo-editing>

    <dxo-paging [pageSize]="50"></dxo-paging>
    <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-search-panel
      [visible]="true"
      [width]="240"
      placeholder="Rechercher..."></dxo-search-panel>
    <dxo-export [enabled]="true"></dxo-export>

    <dxo-header-filter
      [visible]="true"></dxo-header-filter>

    <dxo-filter-builder>
    </dxo-filter-builder>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-builder-popup
      [position]="popupPosition">
    </dxo-filter-builder-popup>


    <!--    TEMPLATE DATE SORTIE -->
    <div *dxTemplate="let cell of 'tplDateSortie'">
      {{cell.row.data.dateSortie}}
    </div>

    <!--    TEMPLATE DLC -->
    <div *dxTemplate="let cell of 'tplDlc'">
      {{cell.row.data.stockDlc}}
      <ng-container
        *ngTemplateOutlet="tplEnErreur;context:{enErreur:cell.row.data.enErreur,erreur:utils.getErreur(cell.row.data.erreurList,ERREURS_CODES.SORTIES_PRODUCTION.SPPE_2)}"></ng-container>
    </div>

    <!--    TEMPLATE ATELIER-->
    <div *dxTemplate="let cell of 'tplAtelier'">
      {{cell.row.data.udpZdpLibelle}}
    </div>

    <!--    TEMPLATE DENREE-->
    <div *dxTemplate="let cell of 'tplDenree'">
      <ng-container
        *ngTemplateOutlet="tplEnErreur;context:{enErreur:cell.row.data.enErreur,erreur:utils.getErreur(cell.row.data.erreurList,ERREURS_CODES.SORTIES_PRODUCTION.SPPE_1)}"></ng-container>
      {{cell.row.data.produitDeclinaisonLibelle}}

    </div>


    <!--    TEMPLATE QUANTITE PREVUE UT-->
    <div *dxTemplate="let cell of 'tplQuantitePrevueUt'">
      {{cell.row.data.quantitePrevueUt | number:'1.4-4'}} <span
      [pTooltip]="cell.row.data.udmUtLibelle">{{cell.row.data.udmUtAbreviation}}</span>
      <ng-container
        *ngTemplateOutlet="tplEnErreur;context:{enErreur:cell.row.data.enErreur,erreur:utils.getErreur(cell.row.data.erreurList,ERREURS_CODES.SORTIES_PRODUCTION.SPPE_4)}"></ng-container>

    </div>

    <!--    TEMPLATE QUANTITE EN STOCK-->
    <div *dxTemplate="let cell of 'tplQuantiteEnStock'">
      <ng-container *ngIf="!utils.isNullOrEmpty(cell.row.data.stockQuantite)">
        {{cell.row.data.stockQuantite | number:'1.4-4'}} <span
        [pTooltip]="cell.row.data.udmUsLibelle">{{cell.row.data.udmUsAbreviation}}</span>
      </ng-container>
    </div>

    <!--    TEMPLATE QUANTITE PREVUE US-->
    <div *dxTemplate="let cell of 'tplQuantitePrevueUs'">
      {{cell.row.data.quantitePrevueUs | number:'1.4-4'}} <span
      [pTooltip]="cell.row.data.udmUsLibelle">{{cell.row.data.udmUsAbreviation}}</span>
      <ng-container
        *ngTemplateOutlet="tplEnErreur;context:{enErreur:cell.row.data.enErreur,erreur:utils.getErreur(cell.row.data.erreurList,ERREURS_CODES.SORTIES_PRODUCTION.SPPE_3)}"></ng-container>
    </div>

    <!--    TEMPLATE RESTE A SORTIR-->
    <div *dxTemplate="let cell of 'tplResteASortir'">
      <div [class.ras-up]="cell.row.data.quantiteReelleUs>=cell.row.data.quantitePrevueUs"
           [class.ras-down]="cell.row.data.quantiteReelleUs<cell.row.data.quantitePrevueUs"
      >
        {{getResteASortir(cell.row.data) | number:'1.4-4'}}
        <span [pTooltip]="cell.row.data.udmUsLibelle">{{cell.row.data.udmUsAbreviation}}</span>
      </div>
    </div>

    <!--    TEMPLATE QUANTITE REELLE US-->
    <div *dxTemplate="let cell of 'tplQuantiteReelleUs'">
      {{cell.row.data.quantiteReelleUs | number:'1.4-4'}} <span
      [pTooltip]="cell.row.data.udmUsLibelle">{{cell.row.data.udmUsAbreviation}}</span>
    </div>

    <!--    TEMPLATE STATUT-->
    <div *dxTemplate="let cell of 'tplStatut'">
        <span class="fa-stack mg-r-10" [pTooltip]="cell.row.data.statutSortiePourProductionLibelle">
              <i class="far fa-square fa-stack-2x"
                 [class.color-proposition-sortie]="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE"
                 [class.color-sortie-validee]="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.SORTIE_VALIDEE"
                 [class.color-sortie-rupture]="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.SORTIE_RUPTURE"
              ></i>
              <strong
                *ngIf="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.PROPOSITION_DE_SORTIE"
                class="fa-stack-1x color-proposition-sortie">PS</strong>
              <strong
                *ngIf="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.SORTIE_VALIDEE"
                class="fa-stack-1x color-sortie-validee">SV</strong>
            <strong
              *ngIf="cell.row.data.statutSortiePourProductionCode===STATUT_SORTIE_POUR_PRODUCTION.SORTIE_RUPTURE"
              class="fa-stack-1x color-sortie-rupture">RS</strong>
        </span>
    </div>

    <!-- UPDATE HEADER CELL TEMPLATE-->
    <div *dxTemplate="let cell of 'updateHeaderCellTemplate'">
      <div tooltipPosition="top" pTooltip="colonne modifiable">
        {{cell.column.caption}}
      </div>
    </div>

  </dx-data-grid>

</ng-template>

<!--TEMPLATE EN ERREUR-->
<ng-template #tplEnErreur let-enErreur="enErreur" let-erreur="erreur">

  <ng-container *ngIf="enErreur && !utils.isNullOrEmpty(erreur)">
    <i class="fas fa-exclamation-triangle mg-l-5" tooltipPosition="left" [pTooltip]="erreur.message"></i>
  </ng-container>

</ng-template>
