
<ng-container *ngIf="!utils.isNullOrEmpty(articleSupplier)">
  <div class="d-flex flex-row ">

    <!-- GRILLE -->
    <div>
      <p-toolbar styleClass="p-mb-4">
        <ng-template pTemplate="left">
          <div class="d-flex flex-row align-items-center">
            <yo-help class="mg-r-15" lang="fr" [file]="pathFile" [dialogMsgSupplier]="help()" [width]="800"></yo-help>
            <p-dropdown
              [options]="articleSupplier.uniteDeProductionList"
              [filter]="true"
              pTooltip="Voir les articles affectés à l'unité de production sélectionnée"
              tooltipPosition="right"
              optionLabel="libelle"
              [style]="{'max-width':'250px','width':'250px'}"
              (onChange)="onchangeUdp($event)"
              [(ngModel)]="articleSupplier.selectedUniteDeProduction">
              <ng-template pTemplate="selectedItem">
                <yo-site [siteId]="articleSupplier.selectedUniteDeProduction.site.id"></yo-site>
                {{articleSupplier.selectedUniteDeProduction.libelle}}
              </ng-template>
              <ng-template let-udp pTemplate="item">
                <yo-site [siteId]="udp.site.id"></yo-site>
                {{udp.libelle}}
              </ng-template>
            </p-dropdown>
            <div class="mg-l-5 mg-r-5">
              <button
                pButton
                class="mg-r-10 cursor"
                icon="fa fa-plus"
                label="CRÉER"
                pTooltip="Créer un article"
                (click)="openCreateArticle($event)">
              </button>
            </div>
            <p-checkbox
              (onChange)="onChangeIsVisibleCaInherited()"
              [binary]="true"
              label="Articles hérités"
              [(ngModel)]="isVisibleCaInherited">
            </p-checkbox>
            <div style="margin-left: 15px;">
              <dx-drop-down-box
                [width]="270"
                [(value)]="treeBoxValue"
                valueExpr="id"
                displayExpr="name"
                placeholder="Filtrer par famille de produit..."
                [showClearButton]="true"
                [dataSource]="treeDataSource"
              >
                <div *dxTemplate="let data of 'content'">
                  <dx-tree-view
                    #treeViewFamillePlat
                    [dataSource]="famillesPlats"
                    [height]="150"
                    dataStructure="plain"
                    keyExpr="id"
                    parentIdExpr="parent.id"
                    selectionMode="multiple"
                    showCheckBoxesMode="normal"
                    [selectNodesRecursive]="true"
                    displayExpr="libelle"
                    [selectByClick]="true"
                    (onItemSelectionChanged)="onChangeParentFamilleProduitSelection($event)"
                  >
                  </dx-tree-view>
                </div>
              </dx-drop-down-box>
            </div>
            <div class="mg-l-15" *ngIf="operationProcessing">
              <dx-progress-bar
                #progressBar
                id="progress-bar-status"
                width="100%"
                [class.complete]="progressBar.value === 100"
                [min]="0"
                [max]="100"
                [value]="progression"
                [statusFormat]="formatProgression"
              >
              </dx-progress-bar>
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="right">
          <button
            type="button"
            pButton
            (click)="opExcel.toggle($event)"
            [disabled]="exportBtnDisabled"
            icon="fas fa-file-excel"
            class="mg-r-5"
            pTooltip="Exporter les articles au format Excel">
          </button>
          <button
            type="button"
            pButton
            icon="fas fa-download"
            class="mg-r-5"
            (click)="opImportXls.toggle($event)"
            pTooltip="Importer les articles à partir d'une feuille Excel">
          </button>

          <yo-button-save
            (click)="save()"
            yoMdTooltip="Enregistrer les modifications de prix">
          </yo-button-save>
        </ng-template>
      </p-toolbar>

      <!--      GRID-->
      <dx-data-grid
        [dataSource]="customStore"
        keyExpr="id"
        [height]="utils.getWindowAvailableHeight(155)"
        [width]="utils.getWindowAvailableWidth(100)"
        [remoteOperations]="true"
        [hoverStateEnabled]="true"
        [allowColumnResizing]="true"
        [rowAlternationEnabled]="true"
        [showRowLines]="true"
        [showBorders]="true"
        (onEditingStart)="onEditingStart($event)"
        #grid>
        <dxi-column dataField="id" caption="Actions" [width]="70"
                    [allowFiltering]="false"
                    [allowGrouping]="false"
                    [allowEditing]="false"
                    cellTemplate="actionsCellTemplate">
        </dxi-column>

        <dxi-column dataField="denreeLibelle" caption="Déclinaison" [width]="200"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    cellTemplate="denreeCellTemplate">
        </dxi-column>

        <dxi-column dataField="produitArticle.libelle" caption="Article" [width]="300"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    [allowEditing]="false"
                    cellTemplate="articleCellTemplate">
        </dxi-column>

        <dxi-column dataField="fournisseur.libelle" caption="Fournisseur" [width]="250"
                    [filterOperations]="['contains']"
                    [allowFiltering]="true"
                    [allowEditing]="false"
                    cellTemplate="fournisseurCellTemplate">
        </dxi-column>

        <dxi-column dataField="groupeAchatCaList"
                    cellTemplate="groupeAchatCaCellTemplate"
                    caption="Prix d'achat en UF"
                    [allowEditing]="true"
                    [allowFiltering]="false"
                    editCellTemplate="prixGacaEditCellTemplate"
                    [width]="250">
        </dxi-column>
        <dxi-column dataField="libelleFamilleProduit" caption="Famille produit" [width]="200"
                    [filterOperations]="['contains']"
                    [allowEditing]="false"
                    [allowFiltering]="false">
        </dxi-column>
        <dxi-column dataField="codeApi" caption="Code Api" [width]="100"
                    [filterOperations]="['contains']"
                    [allowEditing]="false"
                    [allowFiltering]="true">
        </dxi-column>
        <dxi-column dataField="referenceArticle"
                    caption="Réf.Fournisseur"
                    [width]="200"
                    [filterOperations]="['contains']"
                    [allowEditing]="false"
                    [allowFiltering]="true">
        </dxi-column>

        <!-- OPTIONS-->
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple"></dxo-sorting>
        <dxo-editing mode="cell" [allowUpdating]="true" [confirmDelete]="false">
        </dxo-editing>
        <dxo-pager
          [showPageSizeSelector]="true"
          [allowedPageSizes]="[20, 50, 100, 1000]"
          [showNavigationButtons]="false"
          [visible]="true"
          [showInfo]="true"
          infoText="{2} Article(s)">
        </dxo-pager>

        <!-- TEMPLATE DENREE -->
        <div class="d-flex justify-content-between" *dxTemplate="let cell of 'denreeCellTemplate'">
          <div class="no-overflow">
            <div class="width-25">
              <i *ngIf="!cell.row.data.produitDeclinaisonActif" pTooltip="Attention, denrée inactive !"
                 class="fas fa-exclamation-triangle danger-color cursor"></i>
            </div>
            <label class="font-12" pTooltip="{{cell.row.data.produitDeclinaisonLibelle}}">
              {{cell.row.data.produitDeclinaisonLibelle | uppercase}}
            </label><br/>
            <span class="font-10" pTooltip="Code de la denrée">
              {{cell.row.data.produitDeclinaisonCode | uppercase}}
            </span>
          </div>
        </div>

        <!-- TEMPLATE ARTICLE -->
        <div *dxTemplate="let cell of 'articleCellTemplate'">
          <div class="no-overflow">
            <label class="font-12" pTooltip="{{cell.row.data.produitArticleLibelle}}">
              {{cell.row.data.produitArticleLibelle | uppercase}}
            </label>
          </div>
          <div>
            <yo-info-lotmarche-article [uniteDeProductionId]="articleSupplier?.selectedUniteDeProduction?.id"
                                       [lotMarchePdLumpCa]="cell.row.data?.lmPdUpCa"></yo-info-lotmarche-article>
          </div>
        </div>

        <div *dxTemplate="let cell of 'fournisseurCellTemplate'" class="d-flex flex-row">
          <label class="font-12">
            {{cell.row.data.fournisseur.libelle | uppercase}}
          </label>
          <div class="size-icon mg-l-5" *ngIf="!utils.isNullOrEmpty(cell.row.data.fournisseurOwnerLibelle)"
               [pTooltip]="'Hérité du founisseur : ' +cell.row.data.fournisseurOwnerLibelle">
            <span class="fa-stack fa-2x">
              <i class="fas fa-circle fa-stack-2x"></i>
              <strong class="fa-stack-1x custom-letter">H</strong>
            </span>
          </div>
        </div>


        <div *dxTemplate="let cell of 'prixGacaEditCellTemplate'">
          <input type="number" class="input-prix-ca" *ngIf="canModifyActionButton(cell.row.data)"
                 (input)="onChangePrixValueEditCellTemplate($event, cell)"
                 [ngModel]="getPrixValueEditCellTemplate(cell)"/>
          <input type="number" class="input-prix-ca" *ngIf="!canModifyActionButton(cell.row.data)"
                 [ngModel]="getPrixValueEditCellTemplate(cell)"/>
        </div>

        <div *dxTemplate="let cell of 'groupeAchatCaCellTemplate'">
          <div *ngFor="let groupeAchatCa of cell.row.data?.groupeAchatCaList" class="d-flex flex-column">
            <div class="d-flex flex-row align-items-center">
              <ng-container [ngSwitch]="groupeAchatCa.prefere">
                <i *ngSwitchCase="true" class="mg-r-5 fas fa-star cursor" pTooltip="Article préféré"></i>
                <i *ngSwitchCase="false" class="mg-r-5 far fa-star cursor rotate-1-turn"
                   pTooltip="Rendre l'article comme préféré"
                   (click)="updatePrefere(groupeAchatCa, articles ,cell.row.data,true)"></i>
                <ng-container *ngSwitchCase="undefined">
                  <!--                // DO NOTHING-->
                </ng-container>
              </ng-container>
              <div>{{groupeAchatCa.prixUF | currency:'EUR':'symbol':'1.4-4'}}
                / {{cell.row.data.uniteDeFacturation.libelle | slice:0:5}}
                <span
                  [pTooltip]="'Prix préférentiel lié au groupe : '+groupeAchatCa.libelleGroupeAchat">{{cell.row.data.groupeAchatCaList.length > 1 ? '(' + groupeAchatCa.libelleGroupeAchat + ')' : ''}}</span>
              </div>
            </div>
          </div>
        </div>

        <div *dxTemplate="let cell of 'actionsCellTemplate'">
          <yo-cell-button (yoNavigation)="openArticle(cell.row.data)"
                          [yoTooltipShowDelay]="1500"
                          [yoMaxWidth]="true"
                          [yoWidthPercent]="30"
                          [yoTextAlign]="'center'"
                          [yoIconClass]="canModifyActionButton(cell.row.data)?'fa fa-edit':'fas fa-eye'"
                          [pTooltip]="canModifyActionButton(cell.row.data)?'Modifier l\'article':'Voir l\'article'"
                          tooltipPosition="right"
                          showDelay="500">
          </yo-cell-button>

          <ng-container *ngIf="!isDisabledDeleteBtn(cell.row.data)">
            <yo-cell-button (yoNavigation)="deleteArticle(cell.row.data)"
                            [yoTooltipShowDelay]="1500"
                            [yoMaxWidth]="true"
                            [yoWidthPercent]="30"
                            [yoTextAlign]="'center'"
                            [yoIconClass]="'fa fa-trash'"
                            [pTooltip]="'Supprimer l\'article'"
                            tooltipPosition="right"
                            showDelay="500">
            </yo-cell-button>
          </ng-container>

        </div>

        <div *dxTemplate="let cell of 'prixCellTemplate'">
          <div class="p-inputgroup mb-3">
            <dx-number-box
              class="width-75 text-right line-height-24"
              [disabled]="disabledPriceNumberBox(cell.row.data)"
              [(ngModel)]="cell.row.data.prix"
              [value]="cell.row.data.prix">
            </dx-number-box>
            <span class="p-inputgroup-addon">€ / {{cell.row.data.uniteDeFacturation.libelle}}</span>
            <div
              [pTooltip]="cell.row.data.prixPrecedent !== null ? 'Prix précédent : '+cell.row.data.prixPrecedent : 'Pas de précédent prix'"
              tooltipPosition="top">
              <button
                pButton
                type="button"
                [disabled]="cell.row.data.prixPrecedent === null"
                class=" p-button-secondary"
                icon="fas fa-info">
              </button>
            </div>
          </div>
        </div>

      </dx-data-grid>


      🗣️<em class="mg-r-5"> La mise à jour des articles ne peut se faire que sur vos unités de production
      locales.</em>
    </div>
  </div>
</ng-container>

<p-overlayPanel #opImportXls>
  <input type="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
         (change)="importXslx($event)">
</p-overlayPanel>


<!--CREATION ARTICLE, OUVERTURE DE LA SELECTION UDP-->
<yo-creer-article></yo-creer-article>


<!--DIALOG CREATION ARTICLE-->
<yo-dialog></yo-dialog>


<!--PANEL EXPORT EXCEL-->
<p-overlayPanel #opExcel>
  <div class="row">
    <div class="col-md-12">
      <div class="form-group ">
        <label
          [class.requiredControl]="true"
          class="genlabel  font-16"
          pTooltip=""
          showDelay="500">Nombre d'articles à exporter</label>
        <div class="col-sm-10">
          <p-dropdown [options]="exportOptionList"
                      optionLabel="libelle"
                      filter="true"
                      [(ngModel)]="selectExportOption"
                      placeholder="sélection..."
                      [autoDisplayFirst]="false"
                      [style]="{'width':'180px'}">
          </p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 text-right">
      <button pButton
              label="Exporter"
              icon="fas fa-file-excel"
              (click)="exportXslx()"
              [disabled]="selectExportOption === undefined ? true : false">
      </button>
    </div>
  </div>
</p-overlayPanel>
